<template>
    <Details
        title="integration partner"
        :base-path="basePath"
        data-provider="$partnerDataProvider"
        resource="communityPartners"
        :request-params="{communityId, headers}"
    >
        <template v-slot:default="detailsProps">
            <CustomerIntegrationsForm
                :on-submit="detailsProps.handleSubmit"
                :loading="detailsProps.loading"
                @close="detailsProps.redirectToList"
            />
        </template>
    </Details>
</template>

<script>
import CustomerIntegrationsForm from '@/views/auth/customers/communities/Integrations/CustomerIntegrationsForm';
import ModalNavigation from '@/mixins/ModalNavigation';
import Details from '@/components/auth/details/Details';

export default {
    name: "CustomerIntegrationsCreate",
    components: {CustomerIntegrationsForm, Details},
    mixins: [ModalNavigation],
    computed: {
        communityId() {
            return this.$route.params.communityId;
        },
        customerId() {
            return this.$route.params.customerId;
        },
        basePath: function () {
            return this.$route.name.replace('create', 'index');
        },
        headers() {
            return {
                'X-Quext-Customer': this.customerId,
                'x-quext-customer-id': this.customerId,
            }
        },
    },
}
</script>
